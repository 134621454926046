<template>
  <CForm @submit.prevent="create" method="POST">
    <CCard no-header>
      <CCardBody>
        <h3 v-text="method"></h3>
        <CAlert :color="alertType" v-if="message"> {{ message }}</CAlert>
        <CRow>
          <CCol sm="6">
            <CInput label="Name" type="text" v-model="company.name"></CInput>
          </CCol>
          <CCol sm="6">
            <CSelect label="Time Zone" :value.sync="company.timeZone" :plain="true" :options="timeZones">
            </CSelect>
          </CCol>
          <CCol sm="6">
            <CInput label="Stripe Account ID" type="text" v-model="company.stripeConnectAccountId"></CInput>
          </CCol>
          <CCol sm="6">
            <CInput label="VAT Number" type="text" v-model="company.vatNumber"></CInput>
          </CCol>
          <CCol sm="6">
            <CSelect label="Country" :value.sync="company.country" :plain="true" :options="countries">
            </CSelect>
          </CCol>
          <CCol sm="6">
            <CInput label="Company Info Email" type="text" v-model="company.companyEmail"></CInput>
          </CCol>
        </CRow>
        <CRow v-if="paymentMethods.length > 0 && !isLoading">
          <CCol col="6">
            <label>Payment methods</label>
            <CMultiSelect :multiple="true" :selection="true" v-model="selectedPaymentMethods"
              @update="appendValue($event)" optionsEmptyPlaceholder="No payment methods available" selectionType="text"
              :options="paymentMethods" :selected="selectedPaymentMethods" />
          </CCol>
        </CRow>
        <CRow>
          <CCol col="9"></CCol>
          <CCol class="d-flex justify-content-end">
            <CButton color="secondary" @click="goBack" style="margin-right: 15px">
              <CIcon name="cil-list" /> Back to the list
            </CButton>
            <CButton color="primary" type="submit">
              <CIcon name="cil-save" /> Save
            </CButton>
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>
  </CForm>
</template>
  
<script>
import axios from "axios";
export default {
  name: "CreateOrUpdateCompany",
  data: () => {
    return {
      emptyGuid: "00000000-0000-0000-0000-000000000000",
      method: "Create New Company",
      languages: [],
      timeZones: [],
      countries: [],
      paymentMethods: [],
      company: {
        id: "00000000-0000-0000-0000-000000000000",
        name: null,
        companyEmail: null,
        stripeConnectAccountId: null,
        timeZone: null,
        country: null,
        vatNumber: null
      },
      // Alert
      alertType: "success",
      message: null,
      selectedPaymentMethods: [],
      isLoading: false
    };
  },
  methods: {
    appendValue(event) {
      this.selectedPaymentMethods = event;
    },
    goBack() {
      this.$router.go(-1);
    },
    getTimeZones() {
      let self = this;
      axios
        .get(this.$apiAdress + "/v1/ListItem/TimeZones")
        .then(function (response) {
          self.timeZones = response.data;
        })
        .catch(function (error) {
                    self.alertType = "danger";
          setTimeout(function () {
              self.message = null;
          }, 10000);
          self.message = error;
        });
    },
      getCountryList() {
        let self = this;
        axios
          .get(this.$apiAdress + "/v1/ListItem/CountryList")
          .then(function (response) {
            self.countries = response.data;
          })
          .catch(function (error) {
                      self.alertType = "danger";
          setTimeout(function () {
              self.message = null;
          }, 10000);
            self.message = error;
          });
      },
      getPaymentMethods() {
        let self = this;
        axios
          .get(`${this.$apiAdress}/v1/PartnerPaymentMethods`)
          .then(function (response) {
            self.paymentMethods = response.data.map(paymentMethod => {
                return {
                    value: paymentMethod.id,
              text: paymentMethod.paymentMethodsType == 1
                ? "Stripe Card"
                : paymentMethod.paymentMethodsType == 2
                  ? "Stripe Klarna" : paymentMethod.paymentMethodsType == 5
                    ? "Stripe Link"
                    : "Pay Later"
                };
            });
          })
          .catch(function (error) {
                      self.alertType = "danger";
          setTimeout(function () {
              self.message = null;
          }, 10000);
            self.message = error;
          });
      },
      create() {
        let self = this;
        let form = new FormData();
        form.append("id", self.company.id);
        form.append("name", self.company.name);
        form.append("timeZone", self.company.timeZone);
        form.append("stripeConnectAccountId", self.company.stripeConnectAccountId);
        form.append("country", self.company.country);
        form.append("VATNumber", self.company.vatNumber);
        form.append("partnerEmail",self.company.partnerEmail);
        form.append("companyEmail",self.company.companyEmail);
        form.append("paymentMethods", JSON.stringify(self.selectedPaymentMethods));
        axios
          .post(this.$apiAdress + "/v1/Company", form)
          .then((response) => {
            self.company.id = response.data;
                      self.alertType = "success";
          setTimeout(function () {
              self.message = null;
          }, 5000);
            self.message = "Successfully updated partner.";
          })
          .catch(function (error) {
                      self.alertType = "danger";
          setTimeout(function () {
              self.message = null;
          }, 10000);
            self.message = error;
          });
      },
      sleep(milliseconds) {
        return new Promise((resolve) => setTimeout(resolve, milliseconds));
      },
       getCompany(id) {
        let self = this;
        axios
          .get(this.$apiAdress + "/v1/Company/" + id)
          .then(async function (response) {
            self.company = response.data;
            self.selectedPaymentMethods = JSON.parse(response.data.paymentMethods);
            self.isLoading = false;
            await self.sleep(5000);
            self.company.vatNumber = response.data.vatNumber;
          })
          .catch(function (error) {
            self.isLoading = false;
            self.message = error;
          });
      },
    },
    mounted: function () {
      let self = this;
      self.isLoading = true;
      self.getPaymentMethods();
      self.getCountryList();
      self.getTimeZones();
      // Edit OR Create
      if (self.$route.params.id != this.emptyGuid) {
        self.method = "Edit Company";
        self.getCompany(self.$route.params.id);
        
      }
      else{
        self.isLoading = false;
      }
    },
  };
  </script>
  